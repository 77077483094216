/* eslint-disable no-param-reassign */
import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { refreshRequest } from '../store/modules/login';

const baseUrl = process.env.NEXT_PUBLIC_WORKFLOW_SERVICE_URL;

export const injectAuthorizationToken = async (config) => {
  const tokenId = window.localStorage.getItem('tokenId');
  const shouldUseAuth = !config.url.includes('s3.amazonaws.com') && tokenId != null;

  if (shouldUseAuth) {
    config.headers.Authorization = tokenId;
  } else {
    delete config.headers['Authorization'];
  }
  return config;
};

export const refreshToken = async (failedRequest) => {
  let token = window.localStorage.getItem('tokenRefresh');
  const userDetails = JSON.parse(window.localStorage.getItem('userDetails') || '{}');
  const username = userDetails?.email;
  console.log('Session expired. Refreshing token');

  return refreshRequest({ refreshToken: token, username })
    .then((refreshData) => {
      // save new data
      window.localStorage.setItem('tokenId', refreshData.tokenId);
      window.localStorage.setItem('tokenRefresh', refreshData.tokenRefresh);
      window.localStorage.setItem('tokenAccess', refreshData.tokenAccess);
      window.localStorage.setItem('userDetails', JSON.stringify(refreshData.userDetails));

      failedRequest.response.config.headers['Authorization'] = refreshData.tokenId;
      return Promise.resolve();
    })
    .catch((error) => {
      console.log('Failed to refresh. Logging out');
      window.localStorage.removeItem('tokenId');
      window.localStorage.removeItem('tokenRefresh');
      window.localStorage.removeItem('tokenAccess');
      window.localStorage.removeItem('userDetails');

      window.location.href = '/login';

      return Promise.reject(error);
    });
};

export default function initAxios() {
  axios.defaults.baseURL = baseUrl;
  axios.interceptors.request.use(injectAuthorizationToken);

  createAuthRefreshInterceptor(axios, refreshToken);
}
